<template>
  <SvgShapeCrescent class="absolute top-0 w-[25vw] md:w-[11vw] text-blue -right-[0.5vw]"
                    :rotate="-45"
  />

  <SvgShapeArrowStraight class="absolute top-[5vw] md:-top-0 md:w-[13vw] -left-[10vw] md:-left-[1vw] w-[40vw] text-pink" />


  <div class="absolute -bottom-[350vw] w-[308vw] -z-10 left-1/2 transform -translate-x-1/4 text-lemon md:hidden">
    <SvgShapeCrescent class="w-full h-full"
                      :rotate="-61"
                      :animate="false"
    />
  </div>


  <div class="absolute -bottom-[75vw] w-[150vw] -ml-[5vw] -z-10 left-1/2 transform -translate-x-1/2 text-lemon hidden md:block">
    <SvgShapeHalfCircle class="w-full h-full"
                        :rotate="-30"
                        :animate="false"
    />
  </div>
</template>

