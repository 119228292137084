<template>
  <svg viewBox="0 0 415 298"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       class="arrow-curved"
       ref="container"
  >
    <path class="curve"
          ref="curve"
          d="M8.06055 137.25L90.4506 37.6902L136.171 162.65L220.491 30.2402L246.881 268.31L340.381 124.04L388.021 200.24"
          stroke="currentColor"
          stroke-width="20"
          stroke-miterlimit="10"
          pathLength="1"
    />
    <path class="arrow"
          ref="arrow"
          d="M406.89 158.53L388.02 207.84L338.71 188.98"
          stroke="currentColor"
          stroke-width="15.78"
          stroke-miterlimit="10"
          pathLength="1"
    />
  </svg>
</template>

<script setup lang="ts">
import { inView, timeline } from 'motion';
import { onMounted, ref } from 'vue';

type SvgShapeArrowStraightProps = {
  animate?: boolean
}

withDefaults(defineProps<SvgShapeArrowStraightProps>(), {
  animate: true
});

const container = ref(null);
const arrow = ref(null);
const curve = ref(null);

const draw = (progress:number) => ({
  // This property makes the line "draw" in when animated
  strokeDashoffset: 1 - progress,
  visibility: 'visible'
});

onMounted(() => {
  inView(container.value, () => {
    timeline([
      [curve.value, draw(1), { duration: 1.5, delay: 0, easing: 'linear' }],
      [arrow.value, draw(1), { duration: 0.4, delay: 0.2, easing: 'ease-out' }]
    ]);
  }, { amount: 0.4 });
});
</script>

<style scoped>
circle,
path.curve {
  fill: transparent;
  stroke: currentColor;
  stroke-width: 20px;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  visibility: hidden;
}
path.arrow {
  fill: transparent;
  stroke: currentColor;
  stroke-width: 15px;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  visibility: hidden;
}
</style>